import PropTypes from 'prop-types';
import {forwardRef} from 'react';
// @mui
import {useTheme} from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import {RouterLink} from 'src/routes/components';
import {useSettingsContext} from "../settings";

// ----------------------------------------------------------------------

const Logo = forwardRef(({allowCustom = false, disabledLink = false, sx, ...other}, ref) => {
	const theme = useTheme();
	const settings = useSettingsContext();

	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	// OR using local (public folder)
	// -------------------------------------------------------
	// const logo = (
	//   <Box
	//     component="img"
	//     src="/logo/logo_single.svg" => your path
	//     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
	//   />
	// );

	const customLogo = (
		<Box
			sx={{m: 3, cursor: 'pointer', ...sx}}
		>
			<img src={settings.logo} alt="logo" style={{objectFit: "contain"}}/>
		</Box>
	)

	const defaultLogo = (
		<Box
			ref={ref}
			component="div"
			sx={{
				height: 40,
				width: 190,
				display: 'fit-content',
				...sx,
			}}
			{...other}
		>
			<svg viewBox="0 -1.715 2824.348 502.728" xmlns="http://www.w3.org/2000/svg">
				<g
					transform="matrix(3.0339839458465576, 0, 0, 3.0339839458465576, -295.642578125, -64.43047332763672)"
					style={{}}
				>
					<svg
						viewBox="0 0 396 51"
						data-background-color="#000000"
						preserveAspectRatio="xMidYMid meet"
						height={193}
						width={1500}
						xmlns="http://www.w3.org/2000/svg"
						transform="matrix(0.9419503263378317, 0, 0, 1, 185.15099944695976, 48.19255065917969)"
						style={{}}
					>
						<g
							id="tight-bounds"
							transform="matrix(1.039891, 0, 0, 1.039891, 18.553446, 3.984303)"
							style={{}}
						>
							<svg
								viewBox="0 0 395.52 51.39896846391572"
								height="51.39896846391572"
								width="395.52"
								style={{}}
								transform="matrix(1.5448911282858546, 0, 0, 1.615276426129185, -3.736923844607645, -26.608836485459506)"
							>
								<g>
									<svg
										viewBox="0 0 466.4985504773637 60.62283647969361"
										height="51.39896846391572"
										width="395.52"
										transform="matrix(1.191569447517395, 0, 0, 1.5833964685926532, 25.301225662231445, -19.576783240808027)"
										style={{}}
									>
										<g transform="matrix(1,0,0,1,70.97855047736375,8.37840938322828)"/>
										<svg
											viewBox="0 0 60.6228364796936 60.62283647969361"
											height="60.62283647969361"
											width="60.6228364796936"
											transform="matrix(1.0140271374757006, 0, 0, 0.75048508453132, 117.0964584350586, 35.059064443675425)"
											style={{}}
										>
											<g>
												<svg/>
											</g>
											<g
												id="icon-0"
												transform="matrix(0.806452, 0, 0, 0.806452, 8.088844, 2.118364)"
												style={{}}
											>
												<svg
													viewBox="0 0 60.6228364796936 60.62283647969361"
													height="60.62283647969361"
													width="60.6228364796936"
													style={{}}
													transform="matrix(0.9134202599525452, 0, 0, 0.9035747051239014, 0, 0)"
												>
													<g>
														<path
															d="M30 100c-16.569 0-30-13.431-30-30v-40c0-16.569 13.431-30 30-30h40c16.569 0 30 13.431 30 30v40c0 16.569-13.431 30-30 30z"
															fillRule="nonzero"
															stroke="none"
															strokeWidth={1}
															strokeLinecap="butt"
															strokeLinejoin="miter"
															strokeMiterlimit={10}
															strokeDasharray=""
															strokeDashoffset={0}
															fontFamily="none"
															fontWeight="none"
															fontSize="none"
															textAnchor="none"
															style={{
																mixBlendMode: "normal",
																paintOrder: "stroke markers",
																fill: PRIMARY_MAIN,
															}}
															data-fill-palette-color="accent"
															transform="scale(0.60623,0.60623)"
														/>
													</g>
													<g transform="matrix(1,0,0,1,14.924066312219306,12.124567295938718)">
														<svg
															viewBox="0 0 30.77470385525499 36.373701887816175"
															height="36.373701887816175"
															width="30.77470385525499"
														>
															<g>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	version="1.1"
																	x={0}
																	y={0}
																	viewBox="5.917992585042066 0 60.54901115630032 71.565"
																	enableBackground="new 0 0 71.467 71.466"
																	id="dxe-0"
																	width="30.77470385525499"
																	height="36.373701887816175"
																	className="icon-dxe-0"
																	data-fill-palette-color="quaternary"
																>
																	<path
																		d="M53.833 5.402V14.421A3.717 3.717 0 0 1 50.121 18.134H46.96A3.717 3.717 0 0 1 43.248 14.421V4.626H29.138V14.325A3.717 3.717 0 0 1 25.426 18.038H22.264A3.717 3.717 0 0 1 18.552 14.325V5.401A17.885 17.885 0 0 0 5.918 22.514V53.678C5.918 63.543 13.883 71.56 23.745 71.565H48.637C58.501 71.56 66.466 63.544 66.467 53.678V22.514A17.89 17.89 0 0 0 53.833 5.402M57.218 53.678C57.209 58.462 53.358 62.307 48.637 62.316H23.745C19.026 62.307 15.175 58.462 15.166 53.678V32.098C15.175 27.313 19.026 23.468 23.745 23.459H48.637C53.358 23.468 57.209 27.313 57.218 32.098z"
																		data-fill-palette-color="quaternary"
																		style={{fill: "rgb(255, 255, 255)"}}
																	/>
																	<path
																		d="M25.426 0H22.264A0.52 0.52 0 0 0 21.747 0.518V14.325A0.52 0.52 0 0 0 22.264 14.844H25.426A0.52 0.52 0 0 0 25.943 14.325V0.519A0.52 0.52 0 0 0 25.426 0"
																		data-fill-palette-color="quaternary"
																		style={{fill: "rgb(255, 255, 255)"}}
																	/>
																	<path
																		d="M50.121 0.095H46.96A0.52 0.52 0 0 0 46.442 0.613V14.42A0.52 0.52 0 0 0 46.96 14.939H50.121A0.52 0.52 0 0 0 50.639 14.42V0.613A0.52 0.52 0 0 0 50.121 0.095"
																		data-fill-palette-color="quaternary"
																		style={{fill: "rgb(255, 255, 255)"}}
																	/>
																	<path
																		d="M47.48 32.859A0.44 0.44 0 0 0 47.168 32.729 0.44 0.44 0 0 0 46.854 32.859L33.906 45.807 27.749 39.65A0.44 0.44 0 0 0 27.123 39.65L25.208 41.565A0.445 0.445 0 0 0 25.208 42.192L31.656 48.641C31.663 48.648 31.667 48.659 31.674 48.665L33.589 50.58A0.44 0.44 0 0 0 34.214 50.58L49.395 35.4A0.445 0.445 0 0 0 49.395 34.773z"
																		data-fill-palette-color="quaternary"
																		style={{fill: "rgb(255, 255, 255)"}}
																	/>
																</svg>
															</g>
														</svg>
													</g>
												</svg>
											</g>
										</svg>
									</svg>
								</g>
								<defs/>
							</svg>
						</g>
					</svg>
				</g>
				<text
					style={{
						fill: PRIMARY_DARK,
						fontFamily: '"DIN Condensed"',
						fontSize: 500,
						whiteSpace: "pre"
					}}
					x="648.002"
					y="435.3"
				>
					BizCaledarKit
				</text>
			</svg>
		</Box>
	)

	const logo = (allowCustom && settings?.logo) ? customLogo : defaultLogo;

	if (disabledLink) {
		return logo;
	}

	return (
		<Link component={RouterLink} href="/" sx={{display: 'contents'}}>
			{logo}
		</Link>
	);
});

Logo.propTypes = {
	allowCustom: PropTypes.bool,
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default Logo;
