import React from 'react';
import OrganisationDetails from "../onboarding/OrganisationDetails";
import useSWR from "swr";
import {endpoints, fetcher} from "../../utils/axios";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import {paths} from "../../routes/paths";
import Container from "@mui/material/Container";
import {Grid} from "@mui/material";
import Card from "@mui/material/Card";
import {useSettingsContext} from "../../components/settings";
import {useLocales} from "../../locales";

export default function OrganisationDetailsView() {
	const settings = useSettingsContext();
	const {t} = useLocales();
	const {data: organisationData, isLoading} = useSWR(endpoints.organisation.me, fetcher);

	if (isLoading) {
		return (
			<Box sx={{width: '100%'}}>
				<LinearProgress/>
			</Box>
		)
	}

	return (
		<Container maxWidth={settings.themeStretch ? false : 'lg'}>
			<CustomBreadcrumbs
				heading={t('navigations.dashboard.settings.organisationDetails')}
				links={[
					{
						name: t('dashboard'),
						href: paths.dashboard.root,
					},
					{
						name: t('navigations.dashboard.settings.title'),
					},
					{name: t('navigations.dashboard.settings.organisationDetails')},
				]}
				sx={{
					mb: {xs: 3, md: 5},
				}}
			/>
			<Grid container spacing={3}>
				<Grid xs={12} md={12}>
					<Card sx={{p: 3}}>
						<OrganisationDetails organisationData={organisationData}/>
					</Card>
				</Grid>
			</Grid>
		</Container>
	);
}

