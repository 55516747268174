import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen} from 'src/components/loading-screen';
import AppointmentCreatedTemplateEmailPage
	from "../../pages/dashboard/templates/emails/AppointmentCreatedTemplateEmailPage";
import OrganisationDetailsPage from "../../pages/dashboard/settings/organisation-details";
import BusinessHoursPage from "../../pages/dashboard/settings/business-hours";
import CustomisationPage from "../../pages/dashboard/settings/customisation";
import AppointmentUpdatedTemplateEmailPage
	from "../../pages/dashboard/templates/emails/AppointmentUpdatedTemplateEmailPage";
import AppointmentReminderTemplateEmailPage
	from "../../pages/dashboard/templates/emails/AppointmentReminderTemplateEmailPage";
import AppointmentCancelledTemplateEmailPage
	from "../../pages/dashboard/templates/emails/AppointmentCancelledTemplateEmailPage";
import AppointmentCreatedTemplateSmsPage from "../../pages/dashboard/templates/sms/AppointmentCreatedTemplateSmsPage";
import AppointmentReminderTemplateSmsPage from "../../pages/dashboard/templates/sms/AppointmentReminderTemplateSmsPage";
import AppointmentUpdatedTemplateSmsPage from "../../pages/dashboard/templates/sms/AppointmentUpdatedTemplateSmsPage";
import AppointmentCancelledTemplateSmsPage
	from "../../pages/dashboard/templates/sms/AppointmentCancelledTemplateSmsPage";
import SubscriptionPage from "../../pages/dashboard/settings/subscription";
import {canUseFeature} from "../../utils/feature-control";
import {Features} from "../../assets/data/features";
import PaymentPage from "../../pages/dashboard/payment";

// ----------------------------------------------------------------------
const CustomersPage = lazy(() => import('src/pages/dashboard/customers'));
const CustomersCreatePage = lazy(() => import('src/pages/dashboard/customers/new'));

const EmployeesPage = lazy(() => import('src/pages/dashboard/employees'));
const EmployeesCreatePage = lazy(() => import('src/pages/dashboard/employees/new'));

const ServicesPage = lazy(() => import('src/pages/dashboard/services'));
const ServicesCreatePage = lazy(() => import('src/pages/dashboard/services/new'));

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = (features) => {
	const canCustomisePlatform = canUseFeature(features, Features.CustomiseBrandingPlatform);
	const canCustomiseEmail = canUseFeature(features, Features.CustomiseBrandingEmail);
	const canCustomiseSms = canUseFeature(features, Features.CustomiseBrandingSms);

	return [
		{
			path: 'dashboard',
			element: (
				<AuthGuard>
					<DashboardLayout>
						<Suspense fallback={<LoadingScreen/>}>
							<Outlet/>
						</Suspense>
					</DashboardLayout>
				</AuthGuard>
			),
			children: [
				{element: <IndexPage/>, index: true},

				{
					path: 'customers',
					children: [
						{element: <CustomersPage/>, index: true},
						{path: 'add', element: <CustomersCreatePage/>},
					]
				},
				{
					path: 'employees',
					children: [
						{element: <EmployeesPage/>, index: true},
						{path: 'add', element: <EmployeesCreatePage/>},
					]
				},
				{
					path: 'services',
					children: [
						{element: <ServicesPage/>, index: true},
						{path: 'add', element: <ServicesCreatePage/>},
					]
				},
				{path: 'appointments', element: <CalendarPage/>},
				...(canCustomisePlatform || canCustomiseEmail) ? [
					{
						path: 'templates',
						children: [
							...canCustomiseEmail ? [
								{
									path: 'emails',
									children: [
										{
											path: 'appointment',
											children: [
												{path: 'created', element: <AppointmentCreatedTemplateEmailPage/>},
												{path: 'reminder', element: <AppointmentReminderTemplateEmailPage/>},
												{path: 'updated', element: <AppointmentUpdatedTemplateEmailPage/>},
												{path: 'cancelled', element: <AppointmentCancelledTemplateEmailPage/>},
											]
										}
									]
								}] : [],
							...canCustomiseSms ? [{
								path: 'sms',
								children: [
									{
										path: 'appointment',
										children: [
											{path: 'created', element: <AppointmentCreatedTemplateSmsPage/>},
											{path: 'reminder', element: <AppointmentReminderTemplateSmsPage/>},
											{path: 'updated', element: <AppointmentUpdatedTemplateSmsPage/>},
											{path: 'cancelled', element: <AppointmentCancelledTemplateSmsPage/>},
										]
									}
								]
							}] : []
						]
					}] : [],
				{
					path: 'settings',
					children: [
						{
							path: 'organisation-details',
							element: <OrganisationDetailsPage/>
						},
						{
							path: 'business-hours',
							element: <BusinessHoursPage/>
						},
						...canCustomisePlatform ? [
							{
								path: 'customisation',
								element: <CustomisationPage/>
							}] : [],
						{
							path: 'subscription',
							element: <SubscriptionPage/>
						},
						{path: 'payment', element: <PaymentPage/>},
					]
				}


				// { path: 'ecommerce', element: <OverviewEcommercePage /> },
				// { path: 'banking', element: <OverviewBankingPage /> },
				// { path: 'booking', element: <OverviewBookingPage /> },
				// { path: 'file', element: <OverviewFilePage /> },
				// {
				//   path: 'user',
				//   children: [
				//     { element: <UserProfilePage />, index: true },
				//     { path: 'profile', element: <UserProfilePage /> },
				//     { path: 'cards', element: <UserCardsPage /> },
				//     { path: 'list', element: <UserListPage /> },
				//     { path: 'new', element: <UserCreatePage /> },
				//     { path: ':id/edit', element: <UserEditPage /> },
				//     { path: 'account', element: <UserAccountPage /> },
				//   ],
				// },

				// {
				//   path: 'product',
				//   children: [
				//     { element: <ProductListPage />, index: true },
				//     { path: 'list', element: <ProductListPage /> },
				//     { path: ':id', element: <ProductDetailsPage /> },
				//     { path: 'new', element: <ProductCreatePage /> },
				//     { path: ':id/edit', element: <ProductEditPage /> },
				//   ],
				// },
				// {
				//   path: 'order',
				//   children: [
				//     { element: <OrderListPage />, index: true },
				//     { path: 'list', element: <OrderListPage /> },
				//     { path: ':id', element: <OrderDetailsPage /> },
				//   ],
				// },
				// {
				//   path: 'invoice',
				//   children: [
				//     { element: <InvoiceListPage />, index: true },
				//     { path: 'list', element: <InvoiceListPage /> },
				//     { path: ':id', element: <InvoiceDetailsPage /> },
				//     { path: ':id/edit', element: <InvoiceEditPage /> },
				//     { path: 'new', element: <InvoiceCreatePage /> },
				//   ],
				// },
				// {
				//   path: 'post',
				//   children: [
				//     { element: <BlogPostsPage />, index: true },
				//     { path: 'list', element: <BlogPostsPage /> },
				//     { path: ':title', element: <BlogPostPage /> },
				//     { path: ':title/edit', element: <BlogEditPostPage /> },
				//     { path: 'new', element: <BlogNewPostPage /> },
				//   ],
				// },
				// {
				//   path: 'job',
				//   children: [
				//     { element: <JobListPage />, index: true },
				//     { path: 'list', element: <JobListPage /> },
				//     { path: ':id', element: <JobDetailsPage /> },
				//     { path: 'new', element: <JobCreatePage /> },
				//     { path: ':id/edit', element: <JobEditPage /> },
				//   ],
				// },
				// {
				//   path: 'tour',
				//   children: [
				//     { element: <TourListPage />, index: true },
				//     { path: 'list', element: <TourListPage /> },
				//     { path: ':id', element: <TourDetailsPage /> },
				//     { path: 'new', element: <TourCreatePage /> },
				//     { path: ':id/edit', element: <TourEditPage /> },
				//   ],
				// },
				// { path: 'file-manager', element: <FileManagerPage /> },
				// { path: 'mail', element: <MailPage /> },
				// { path: 'chat', element: <ChatPage /> },

				// { path: 'kanban', element: <KanbanPage /> },
				// { path: 'permission', element: <PermissionDeniedPage /> },
				// { path: 'blank', element: <BlankPage /> },
			],
		},
	]
};
