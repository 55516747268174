import React from 'react';
import Stack from "@mui/material/Stack";
import FormProvider, {RHFEditor, RHFTextField} from "../../../../components/hook-form";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {enqueueSnackbar} from "notistack";
import EmailTemplatePreview from "./EmailTemplatePreview";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import {useLocales} from "../../../../locales";


const variables = [
	"{{customer.name}}", "{{customer.email}}", "{{customer.phone}}",
	"{{employee.name}}", "{{employee.email}}", "{{employee.phone}}",
	"{{appointment.date}}", "{{appointment.time}}",
	"{{service.name}}", "{{service.duration}}", "{{service.price}}", "{{service.currency}}"
]

const AppointmentTemplate = ({
	                             handleUpdate,
	                             defaultValues,
                             }) => {
	const { t } = useLocales();
	const Schema = Yup.object().shape({
		subject: Yup.string().required().max(70),
		title: Yup.string().required().max(50),
		body: Yup.string().required().max(1000),
		closing: Yup.string().required().max(1000),
	});

	const methods = useForm({
		resolver: yupResolver(Schema),
		defaultValues
	});

	const {
		watch,
		handleSubmit,
	} = methods;
	const values = watch();

	const onSubmit = handleSubmit(async (data) => handleUpdate(data), (errors) => {
		console.error(errors);
	});

	return (
		<FormProvider methods={methods} onSubmit={onSubmit}>
			<Stack spacing={3}>
				<Typography variant="subtitle1" sx={{pb: 0}}>Variables:</Typography>
				<Grid container spacing={1} sx={{mb: 2, pt: 0}}>
					{variables.map((variable) => (
						<Grid item>
							<Chip label={variable} key={variable} onClick={() => {
								navigator.clipboard.writeText(variable);
								enqueueSnackbar(`Variable ${variable} copied to clipboard. Use paste to add it where you want it.`, {variant: 'success'});
							}}/>
						</Grid>
					))}
				</Grid>
				<RHFTextField name="subject" label={t('fields.subject')} maxChars={70}/>
				<RHFTextField name="title" label={t('fields.title')} maxChars={50}/>

				<RHFEditor template simple name="body" label={t('fields.body')} id="body" maxChars={1000}/>
				<RHFEditor template simple name="closing" label={t('fields.closing')} maxChars={1000}/>
				<LoadingButton variant="contained" type="submit">{t('buttons.update')}</LoadingButton>
				<Divider>{t('titles.previewTemplate')}</Divider>
				<EmailTemplatePreview title={values?.title} body={values?.body} closing={values?.closing}/>
			</Stack>
		</FormProvider>
	);
};
AppointmentTemplate.propTypes = {
	defaultValues: PropTypes.object.isRequired,
	handleUpdate: PropTypes.func.isRequired,
};

export default AppointmentTemplate;
