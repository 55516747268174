import { Helmet } from 'react-helmet-async';
import Organisations from "../sections/organisations";
// sections

// ----------------------------------------------------------------------

export default function OrganisationsPage() {
	return (
		<>
			<Helmet>
				<title> Organisations</title>
			</Helmet>

			<Organisations/>
		</>
	);
}
