import React from 'react';
import Stack from "@mui/material/Stack";
import FormProvider, {RHFTextField} from "../../../../components/hook-form";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {enqueueSnackbar} from "notistack";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";

const maxLength = 250;


const variables = ["{{customer.name}}", "{{customer.email}}", "{{customer.phone}}", "{{employee.name}}", "{{employee.email}}", "{{employee.phone}}", "{{appointment.date}}", "{{appointment.time}}", "{{service.name}}", "{{service.duration}}", "{{service.price}}", "{{service.currency}}"]

const AppointmentSmsTemplate = ({
	                                handleUpdate, defaultValues,
                                }) => {
	const Schema = Yup.object().shape({
		body: Yup.string().required().max(maxLength), closing: Yup.string(),
	});

	const methods = useForm({
		resolver: yupResolver(Schema), defaultValues
	});

	const {
		handleSubmit,
	} = methods;

	const onSubmit = handleSubmit(async (data) => handleUpdate(data), (errors) => {
		console.error(errors);
	});

	return (<FormProvider methods={methods} onSubmit={onSubmit}>
		<Stack spacing={3}>
			<Typography variant="subtitle1" sx={{pb: 0}}>Variables:</Typography>
			<Grid container spacing={1} sx={{mb: 2, pt: 0}}>
				{variables.map((variable) => (<Grid item>
					<Chip label={variable} key={variable} onClick={() => {
						navigator.clipboard.writeText(variable);
						enqueueSnackbar(`Variable ${variable} copied to clipboard. Use paste to add it where you want it.`, {variant: 'success'});
					}}/>
				</Grid>))}
			</Grid>
			<RHFTextField
				multiline
				maxRows={10}
				name="body"
				label="Body"
				maxChars={maxLength}
			/>
			<LoadingButton variant="contained" type="submit">Update</LoadingButton>
		</Stack>
	</FormProvider>);
};
AppointmentSmsTemplate.propTypes = {
	defaultValues: PropTypes.object.isRequired, handleUpdate: PropTypes.func.isRequired,
};

export default AppointmentSmsTemplate;
