import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// components
import {useLocales} from "../../locales";
import useSWR from "swr";
import {endpoints, fetcher} from "../../utils/axios";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import moment from "moment";
import Chip from "@mui/material/Chip";
import DownloadIcon from '@mui/icons-material/Download';

// ----------------------------------------------------------------------

export default function PaymentInvoices({sx, ...other}) {
	const {t} = useLocales();
	const {data, isLoading} = useSWR(endpoints.subscriptions.paymentInvoices, fetcher);

	if (isLoading) {
		return (
			<Box sx={{p: 5, borderRadius: 2, bgcolor: 'background.neutral', ...sx}} {...other}>
				<Typography variant="h6" sx={{mb: 5}}>
					{t('components.payment.invoices.title')}
				</Typography>
				<Box display="flex" flexDirection="column" alignItems="center" sx={{m: 5}}>
					<CircularProgress/>
				</Box>
			</Box>
		);
	}

	const getStatusColor = (status) => {
		switch (status) {
			case 'paid':
				return 'success';
			case 'void':
				return 'warning';
			case 'uncollectible':
				return 'error';
			default:
				return 'info';
		}
	}


	return (
		<Box
			sx={{
				p: 5,
				borderRadius: 2,
				bgcolor: 'background.neutral',
				...sx,
			}}
			{...other}
		>
			<Typography variant="h6" sx={{mb: 5}}>
				{t('components.payment.invoices.title')}
			</Typography>

			<Stack spacing={2.5}>
				{data?.map((invoice) => (
					<>
						<Stack direction="row" justifyContent="space-between">
							<Typography variant="body2" sx={{color: 'text.primary'}}>
								<Chip sx={{ mr: 2}} color={getStatusColor(invoice.status)} label={t(`components.payment.invoices.statuses.${invoice.status}`)} /> {moment.unix(invoice.date).format('D MMMM YYYY')}
							</Typography>
							<Typography variant="body2" sx={{color: 'text.primary'}}>
								{invoice.amount} {invoice.currency}
							</Typography>
							<LoadingButton startIcon={<DownloadIcon />} variant="contained" onClick={() => {
								const link = document.createElement('a');
								link.href = invoice.pdf;
								document.body.appendChild(link);
								link.click();
								document.body.removeChild(link);
							}}>{t('buttons.download')}</LoadingButton>
						</Stack>

						<Divider sx={{borderStyle: 'dashed'}}/>
					</>
				))}

			</Stack>
		</Box>
	);
}

PaymentInvoices.propTypes = {
	sx: PropTypes.object,
};
