import {Helmet} from 'react-helmet-async';
import {useLocales} from "../../../locales";
import BusinessHoursView from "../../../sections/settings/BusinessHoursView";
import {useAuthContext} from "../../../auth/hooks";
import NoAccessPage from "../../../sections/access/NoAccess";

export default function BusinessHoursPage() {
	const {t} = useLocales();

	const authContext = useAuthContext();
	const canAccess = ['ADMIN', 'MANAGER'].includes(authContext?.user?.role);

	if (!canAccess) {
		return (
			<NoAccessPage/>
		);
	}

	return (
		<>
			<Helmet>
				<title>{t('seo.servicesAdd.title')} - {t('appName')}</title>
				<meta name="description" content={t('seo.servicesAdd.description')}/>
			</Helmet>

			<BusinessHoursView/>
		</>
	);
}
